import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";

import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";
import {
  CurrentNavigator,
  NavigatorItemPart,
  NavigatorLocation,
  NavigatorLocationItem,
  CurrentActivePage,
  NavigatorPageType,
  CurrentStoreNavigator,
  CurrentNavigatorFinal,
  NavigatorItemPartViewer,
  NavigatorLocationViewerItem,
  NavigatorPartViewer,
  CurrentStoreNavigatorLocationMapping,
} from "app/models/currentNavigator";
import { NavModel } from "app/models/nav";
import { PartLookupViewer } from "app/models/facility";
import { ShapeProp } from "components/ImageAnnotator/types";

export default class NavigatorStore {
  currentNavigator: CurrentNavigator | null = null;
  currentNavigatorModel: NavModel | null = null;
  currentStoreNavigatorModel: CurrentStoreNavigator | null = null;
  currentStoreNavigatorFinal: CurrentNavigatorFinal | null = null;
  currentStoreNavigatorPart: PartLookupViewer | null = null;
  currentStoreNavigatorSearchPart: NavigatorPartViewer | null = null;
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  getCurrentNavigation = async () => {
    try {
      this.setLoading(true);
      const itemParts: NavigatorItemPart[] = [
        {
          partId: "992952cf-5533-4c51-361a-08dbae91da66",
          partName: "Beasam or Stanley Automatic Sliding Door",
          partDescription: "",
          partFamilyId: "8e7cf4fd-413a-4976-dad4-08dbae8b6be6",
          partFamilyName: "Door",
        },
        {
          partId: "d09b6efb-2175-46d2-361b-08dbae91da66",
          partName: "Adams Rite Lockset",
          partDescription: "",
          partFamilyId: "2fea2391-ad21-4323-dad5-08dbae8b6be6",
          partFamilyName: "Lockset",
        },
      ];
      const locationItems: NavigatorLocationItem[] = [
        {
          itemId: "7331f8d4-3550-4a17-1d16-08dbae921f63",
          itemName: "Automatic Sliding Door",
          itemDescription: "",
          parts: itemParts,
        },
      ];
      const locations: NavigatorLocation[] = [
        {
          locationId: "2ce14ff5-7fc0-4e56-5eed-08dbae7b6c06",
          locationName: "Guest Entrance Doors",
          locationDescription: "",
          locationImageUrl: "",
          items: locationItems,
          points: [
            [9, 14],
            [8, 70],
            [150, 70],
            [151, 16],
          ],
        },
      ];
      const rdata: CurrentNavigator = {
        facilityId: "657d1a43-cba9-461f-8971-08dbae7b2951",
        facilityName: "Target",
        facilityImageUrl: "https://res.cloudinary.com/dyhrncorp/image/upload/v1693964819/j4d2jwyt2n6tc8uogs4h.png",
        locations: locations,
        activePage: { pageType: NavigatorPageType.Facility, entityId: "657d1a43-cba9-461f-8971-08dbae7b2951", pageModel: null },
      };

      runInAction(() => (this.currentNavigator = rdata));
      this.setLoading(false);
      return this.currentNavigator;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getCurrentNavigationById = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.detailNav(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      this.setCurrentNavigationModel(response.data);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  getPartMappingsById = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await agent.StoreNavigator.partMappings(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      this.setCurrentStoreNavigationPart(response.data);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  getFirstPartById = (id: string) => {
    try {
      if (!this.currentStoreNavigatorFinal || !this.currentStoreNavigatorFinal?.locations) {
        return null;
      }
      this.currentStoreNavigatorFinal.locations.forEach((l) => {
        let parLoc = l;
        console.log("getFirstPartById location", { ...parLoc });
        if (parLoc?.locationViewer?.items) {
          parLoc.locationViewer.items.forEach((li) => {
            let liLoc = li;
            if (li?.itemViewer?.parts) {
              li.itemViewer.parts.forEach((p) => {
                if (p?.partViewer?.id == id) {
                  console.log("getFirstPartById partMatch", { ...p.partViewer });
                  this.setCurrentStoreNavigatorSearchPart(p?.partViewer);
                  return;
                }
                if (p?.partViewer?.partKits) {
                  p.partViewer.partKits.forEach((pk) => {
                    if (pk?.id == id) {
                      this.setCurrentStoreNavigatorSearchPart(pk);
                      console.log("getFirstPartById partKitMatch", { ...pk });
                      return;
                    }
                  });
                }
              });
            }
            if (this.currentStoreNavigatorSearchPart) {
              return;
            }
          });
          if (this.currentStoreNavigatorSearchPart) {
            return;
          }
        }
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getLocationById = (id: string) => {
    try {
      if (!this.currentStoreNavigatorFinal || !this.currentStoreNavigatorFinal?.locations) {
        return null;
      }
      let location = null;
      this.currentStoreNavigatorFinal.locations.forEach((l) => {
        let parLoc = l;

        if (l?.locationViewer?.id == id) {
          location = l;
          return;
        }
      });
      return location;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getItemById = (id: string, setLocation: boolean) => {
    try {
      if (!this.currentStoreNavigatorFinal || !this.currentStoreNavigatorFinal?.locations) {
        return null;
      }
      let item: NavigatorLocationViewerItem | null = null;
      this.currentStoreNavigatorFinal.locations.forEach((l) => {
        let parLoc = l;
        console.log("getItemById location", { ...parLoc });
        if (parLoc?.locationViewer?.items) {
          parLoc.locationViewer.items.forEach((li) => {
            if (li?.itemViewer?.id == id) {
              item = li;
              console.log("getItemById itemMatch", { ...item });
              return;
            }
          });
        }
        if (item != null) {
          if (setLocation) {
            this.setCurrentStoreNavigationFinal({
              ...this.currentStoreNavigatorFinal,
              activeLocation: parLoc,
            });
          }
          return;
        }
      });

      return item;
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  getStoreNavigation = async (id: string) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.detailNav(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      this.setCurrentNavigationModel(response.data);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  getCurrentStoreNavigationById = async (id?: string | "") => {
    this.setLoading(true);
    try {
      const response = await agent.StoreNavigator.details(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      this.setCurrentStoreNavigationFinal(response.data);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  setCurrentStoreNavigationFinal = async (model: CurrentNavigatorFinal) => {
    runInAction(() => (this.currentStoreNavigatorFinal = model));
  };
  setCurrentStoreNavigationPart = async (model: PartLookupViewer) => {
    runInAction(() => (this.currentStoreNavigatorPart = model));
  };
  setCurrentStoreNavigatorSearchPart = async (model: NavigatorPartViewer) => {
    runInAction(() => (this.currentStoreNavigatorSearchPart = model));
  };

  setCurrentNavigation = async (updatedNavigation: CurrentNavigator) => {
    runInAction(() => (this.currentNavigator = updatedNavigation));
  };

  setCurrentNavigationModel = async (updatedNavigation: NavModel) => {
    runInAction(() => (this.currentNavigatorModel = updatedNavigation));
  };

  getFacilityAnnotations = () => {
    const shapes: ShapeProp[] = [];
    if (this?.currentStoreNavigatorFinal && this.currentStoreNavigatorFinal?.annotations) {
      this.currentStoreNavigatorFinal.annotations.forEach((bm) => {
        if (bm?.mapping) {
          shapes.push({ ...JSON.parse(bm.mapping), id: bm.id, mappingId: bm.id });
        }
      });
    }
    if (this?.currentStoreNavigatorFinal && this?.currentStoreNavigatorFinal?.locations) {
      this.currentStoreNavigatorFinal.locations.forEach((fl) => {
        if (fl?.mappingStyle) {
          shapes.push({ ...JSON.parse(fl.mappingStyle), id: fl.id, mappingId: fl.id });
        }
      });
    }
    return shapes;
  };
}
