import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ItemPart } from "../models/itemPart";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";

export default class ItemPartStore {
  itemParts: ItemPart[] | null = null;
  itemPartMetaData: Omit<PaginatedResult<ItemPart>, "data"> | null = null;

  loading = false; // modal window buttons loading state
  loadingInitial = false; // list view table loading state

  constructor() {
    makeAutoObservable(this);
  }

  // loading state setter
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading state setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setItemPartMetaData = (metaData: Omit<PaginatedResult<ItemPart>, "data">) => {
    runInAction(() => {
      this.itemPartMetaData = metaData;
    });
  };
  // load items - paginated list of items from api
  loadItemParts = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
      };
      const { data, ...metaData } = await agent.ItemParts.search(params);
      runInAction(() => {
        this.itemParts = data;
      });
      this.setItemPartMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadAllItemParts = async () => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.ItemParts.list(); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.itemParts = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
  // create item
  createItemPart = async (item: ItemPart) => {
    this.setLoading(true);
    try {
      const itemRequestBody = {
        ItemId: item.itemId,
        PartId: item.partId,
        RelationshipTypeId: item.relationshipTypeId,
        MappingType: item.mappingType,
        MappingLabel: item.mappingLabel,
        MappingStyle: item.mappingStyle,
      };
      const response = await agent.ItemParts.create(itemRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  // update item
  updateItemPart = async (item: ItemPart) => {
    this.setLoading(true);
    try {
      const response = await agent.ItemParts.update(item);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  // delete item
  deleteItemPart = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.ItemParts.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadParts = async (id: string) => {
    try {
      const response = await agent.Items.getParts(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.itemParts = response.data;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  resetItemParts = () => {
    runInAction(() => {
      this.itemParts = null;
    });
  };
}
