import { Box, Button, ButtonProps, IconButton, styled, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { FC } from "react";
import { topBarHeight } from "./consts";
import { ArrowLeft, Save } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { grey } from "@mui/material/colors";
const NavBackButton = styled(IconButton)<ButtonProps>(({ theme }) => ({
  "&:hover": {
    backgroundColor: grey[300],
  },
}));

const TopNavLink: FC<{ to: string; variant?: "secondary" | "tertiary" }> = ({ to, variant = "secondary" }) => {
  const iconButtonSides = 40;
  const theme = useTheme();
  return (
    <Link to={to}>
      <NavBackButton
        sx={{
          width: iconButtonSides,
          height: iconButtonSides,
          borderRadius: "6px",
          border: "2px solid",
          borderColor: "hsla(212, 14%, 77%, 1)",
          marginRight: theme.spacing(2),
        }}
        aria-label="save"
      >
        <ArrowBackIcon sx={{ color: "secondary.400" }} />
      </NavBackButton>
    </Link>
  );
};
export default observer(TopNavLink);
