import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { ShapeProp } from "components/ImageAnnotator/types";

export default class EditorStore {
  loading = false;
  loadingInitial = false;
  selectedId: string = "";
  hoverSelectedId: string = "";
  highlightId: string = "";
  highlightGroupId: string = "";
  imageUrl: string = "";
  activeEditShapeProp?: ShapeProp | null;
  shapeProps: ShapeProp[] = [];
  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setSelectedId(id?: string) {
    runInAction(() => {
      this.selectedId = id ?? "";
    });
  }

  setHoverSelectedId(id?: string) {
    runInAction(() => {
      this.hoverSelectedId = id ?? "";
    });
  }
  setHighlightId(id?: string) {
    runInAction(() => {
      this.highlightId = id ?? "";
    });
  }
  setHighlightGroupId(id?: string) {
    runInAction(() => {
      this.highlightGroupId = id ?? "";
    });
  }
  setImageUrl(url: string) {
    runInAction(() => {
      this.imageUrl = url;
    });
  }

  reset = () => {
    runInAction(() => {
      this.selectedId = "";
      this.shapeProps = [];
    });
  };

  setActiveEditShapeProp = (state: ShapeProp | null) => {
    runInAction(() => {
      this.activeEditShapeProp = state;
    });
  };

  setShapeProps = (state: ShapeProp[] | []) => {
    runInAction(() => {
      this.shapeProps = state;
    });
  };
}
