import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { SearchParams } from "app/models/searchParams";
import { ItemPart } from "app/models/itemPart";
import { FacilitySearchResult } from "app/models/facility";
import { ShapeProp, NavigatorObjectProp } from "components/ImageAnnotator/types";

export default class ViewerStore {
  annotationShapeProps: ShapeProp[] | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setShapeProps = (shapeProps: ShapeProp[]) => {
    runInAction(() => {
      this.annotationShapeProps = shapeProps;
    });
  };

  getShapeProps = () => {
    const result = window.localStorage.shapePropsSaves || "[]";
    try {
      const shapePropObj = JSON.parse(result);
      runInAction(() => (this.annotationShapeProps = shapePropObj));
    } catch (err) {
      console.log(err);
    }
  };

  saveShapeProps = async (shapeProps: ShapeProp[]) => {
    try {
      const sprops = shapeProps.slice();
      const spropValidated: ShapeProp[] = [];

      sprops.forEach((sp) => {
        if (sp.tool == "rect") {
          if (sp.width == 0 || sp.height == 0) return;

          const spData = {
            ...sp,
            colorOnHover: true,
            navigatorObjectProp: { navigatorObjectType: "location", id: "d3ab4734-da0a-4ff9-e7ca-08dbaec7b71c" } as NavigatorObjectProp,
          };

          spropValidated.push(spData);
        } else {
          spropValidated.push(sp);
        }
      });
      window.localStorage.shapePropsSaves = JSON.stringify(spropValidated);
    } catch (err) {
      console.log(err);
    }
  };
}
