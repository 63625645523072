import { Brightness4 } from "@mui/icons-material";
import {
  Alert,
  alpha,
  AppBar,
  Badge,
  Box,
  Button,
  Chip,
  ClickAwayListener,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemText,
  Paper,
  Popper,
  styled,
  Tab,
  Tabs,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { H2 } from "../../components/Typography";
import { FC, useRef, useState } from "react";
import ProfileButton from "./profileButton/ProfileButton";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import ThemeIcon from "../../icons/ThemeIcon";
import TopNavRoot from "./TopNavRoot";
import SearchIcon from "icons/SearchIcon";
import { NavigatorPageType } from "app/models/currentNavigator";

export interface SearchResultIeProps {
  isMobile?: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`search-result-tabpanel-${index}`} aria-labelledby={`search-result-tab-${index}`} {...other}>
      {value === index && <Box sx={{ p: 1, maxHeight: "60vh", overflow: "auto" }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `search-result-tab-${index}`,
    "aria-controls": `search-result-tabpanel-${index}`,
  };
}
const SearchDropdownResults: FC<SearchResultIeProps> = ({ isMobile }: SearchResultIeProps) => {
  const { commonStore, navigatorStore } = useStore();
  const [value, setValue] = useState(0);
  const shapeStyles = { width: 22, height: 22, marginTop: "0px", marginLeft: "4px", display: "flex", justifyContent: "center", alignItems: "center" };
  const shapeCircleStyles = { borderRadius: "50%" };
  const theme = useTheme();
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const onPartClick = () => {
    navigatorStore.setCurrentStoreNavigationFinal({
      ...navigatorStore.currentStoreNavigatorFinal,
      activePart: undefined,
      activePageType: NavigatorPageType.Item,
    });
  };

  let mode = "dark";
  if (commonStore.darkMode === true) {
    mode = "dark";
  } else {
    mode = "light";
  }

  if (
    (commonStore?.searchResults?.partMappings?.length ?? 0) == 0 &&
    (commonStore?.searchResults?.items?.length ?? 0) == 0 &&
    (commonStore?.searchResults?.locations?.length ?? 0) == 0
  )
    return (
      <Paper>
        <Box sx={{ height: 40, display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Typography component="span" variant="body2">
            No results found for given search
          </Typography>
        </Box>
      </Paper>
    );
  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" centered>
          <Tab
            {...a11yProps(0)}
            sx={{ width: 100 }}
            label={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Locations
                <Box
                  component="span"
                  sx={{
                    flexShrink: 0,
                    flexGrow: 0,

                    bgcolor: theme.palette.primary.main,
                    ...shapeStyles,
                    ...shapeCircleStyles,
                  }}
                >
                  <Typography component="span" variant="body2" color="white" sx={{ display: "inline", fontSize: "12px" }}>
                    {commonStore?.searchResults?.locations?.length ?? 0}
                  </Typography>
                </Box>
              </Box>
            }
          />

          <Tab
            {...a11yProps(1)}
            sx={{ width: 100 }}
            label={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Items
                <Box
                  component="span"
                  sx={{
                    flexShrink: 0,
                    flexGrow: 0,

                    bgcolor: theme.palette.primary.main,
                    ...shapeStyles,
                    ...shapeCircleStyles,
                  }}
                >
                  <Typography component="span" variant="body2" color="white" sx={{ display: "inline", fontSize: "12px" }}>
                    {commonStore?.searchResults?.items?.length ?? 0}
                  </Typography>
                </Box>
              </Box>
            }
          />
          <Tab
            {...a11yProps(2)}
            sx={{ width: 100 }}
            label={
              <Box
                component="span"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Parts
                <Box
                  component="span"
                  sx={{
                    flexShrink: 0,
                    flexGrow: 0,

                    bgcolor: theme.palette.primary.main,
                    ...shapeStyles,
                    ...shapeCircleStyles,
                  }}
                >
                  <Typography component="span" variant="body2" color="white" sx={{ display: "inline", fontSize: "12px" }}>
                    {commonStore?.searchResults?.partMappings?.length ?? 0}
                  </Typography>
                </Box>
              </Box>
            }
          />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <List sx={{ paddingTop: 0 }}>
          {commonStore?.searchResults != null &&
            commonStore?.searchResults?.locations?.length > 0 &&
            commonStore?.searchResults?.locations
              ?.slice()
              ?.sort((a, b) => ((a?.name ?? "") > (b?.name ?? "") ? 1 : (b?.name ?? "") > (a?.name ?? "") ? -1 : 0))
              .map((res, i) => {
                return (
                  <ListItem
                    key={res?.id}
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (res?.id) {
                        const locationNav = navigatorStore.getLocationById(res.id);

                        if (locationNav) {
                          commonStore.setSearchResultsOpen(false);
                          navigatorStore.setCurrentStoreNavigationFinal({
                            ...navigatorStore.currentStoreNavigatorFinal,
                            activeLocation: locationNav,
                            activePageType: NavigatorPageType.Location,
                          });
                        }
                      }
                    }}
                  >
                    <Box display="flex" width="100%">
                      <Box sx={{ flexGrow: 1 }}>{res.name}</Box>
                    </Box>
                  </ListItem>
                );
              })}
        </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <List>
          {commonStore?.searchResults != null &&
            commonStore?.searchResults?.items?.length > 0 &&
            commonStore?.searchResults?.items
              ?.slice()
              ?.sort((a, b) => ((a?.name ?? "") > (b?.name ?? "") ? 1 : (b?.name ?? "") > (a?.name ?? "") ? -1 : 0))
              .map((res, i) => {
                return (
                  <ListItem
                    key={res?.id}
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (res?.id) {
                        const itemNav = navigatorStore.getItemById(res.id, true);
                        debugger;
                        if (itemNav) {
                          commonStore.setSearchResultsOpen(false);
                          navigatorStore.setCurrentStoreNavigationFinal({
                            ...navigatorStore.currentStoreNavigatorFinal,
                            activeItem: itemNav,
                            activePageType: NavigatorPageType.Item,
                          });
                        }
                      }
                    }}
                  >
                    <Box display="flex" width="100%">
                      <Box sx={{ flexGrow: 1 }}>{res.name}</Box>
                    </Box>
                  </ListItem>
                );
              })}
        </List>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <List>
          {commonStore?.searchResults != null &&
            commonStore?.searchResults?.partMappings?.length > 0 &&
            commonStore?.searchResults?.partMappings
              ?.slice()
              ?.sort((a, b) => ((a?.partName ?? "") > (b?.partName ?? "") ? 1 : (b?.partName ?? "") > (a?.partName ?? "") ? -1 : 0))
              .map((res, i) => {
                return (
                  <ListItem
                    key={res?.partId}
                    sx={{ display: "flex", cursor: "pointer" }}
                    onClick={() => {
                      if (res?.partId) {
                        commonStore.setSearchResultsOpen(false);
                        navigatorStore.getFirstPartById(res.partId);
                        navigatorStore.setCurrentStoreNavigationFinal({
                          ...navigatorStore.currentStoreNavigatorFinal,
                          activePart: undefined,
                          activeLocation: undefined,
                          activeItem: undefined,
                          activePageType: NavigatorPageType.PartSearch,
                        });
                      }
                    }}
                  >
                    <Box display="flex" width="100%">
                      <Box sx={{ display: "inline", flexGrow: 1 }}>
                        {res.partName}
                        {res?.partNumber && (
                          <Box sx={{ display: "inline", flexGrow: 0, marginLeft: theme.spacing(1), fontWeight: 600 }}>{res.partNumber}</Box>
                        )}
                      </Box>
                      <Box>
                        {res?.partFamily?.name && <Chip color="secondary" size="small" variant="filled" label={res?.partFamily?.name}></Chip>}
                      </Box>
                    </Box>
                  </ListItem>
                );
              })}
        </List>
      </CustomTabPanel>
    </Paper>
  );
};

export default observer(SearchDropdownResults);
