import * as React from "react";
import { useEffect } from "react";
import { StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { ThemeProvider } from "@emotion/react";
import { nanoTheme } from "./theme";
import { observer } from "mobx-react-lite";
import { ToastContainer } from "material-react-toastify";
import { useStore } from "./app/stores/store";
import LoadingScreen from "./components/LoadingScreen";
import ScrollToTop from "app/utils/ScrollToTop";
import { Outlet, useLocation } from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { injectStores } from "@mobx-devtools/tools";
function App() {
  const { commonStore, itemStore, currentUserStore, facilityStore, locationStore, editorStore } = useStore();
  const isDevelopment: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  injectStores({
    commonStore,
    currentUserStore,
    facilityStore,
    locationStore,
    itemStore,
    editorStore,
  });

  // get the current user (otherwise reloading browser will clear mobx)
  useEffect(() => {
    if (commonStore.token) {
      // token present, get user from API

      if (currentUserStore.currentUser) return;
      currentUserStore.getCurrentUser().finally(() => commonStore.setAppLoaded());
    } else {
      // no token present, not logged in
      commonStore.setAppLoaded();
    }
  }, [commonStore, currentUserStore]);

  // useEffect(() => {

  // }, [location]);

  // checking if there is a subdomain
  useEffect(() => {
    var host = window.location.host.toLowerCase();
    //if (isDevelopment) host = "target.dhpace.com";

    const subdomain = host.split(".");

    //const targetRegex = /.*target.*\.dhpace\.com/g;
    //const targetFound = host.match(targetRegex);

    //if (host.includes("targettest.dhpace.com")) {
    //if (targetFound != null) {
    //  commonStore.setTenant("target");
    //  commonStore.setSubdomain(); // set to true --> will hide the tenant selection on client login
    //} else 
    if (!host.includes("azurewebsites") && !host.includes("navigatoradmin")) {
      const minimumSegments = isDevelopment ? 2 : 3; // adjust to your url structure needs
      //const minimumSegments = 3; 
      if (subdomain.length == minimumSegments) {
        commonStore.setTenant(subdomain[0]);
        commonStore.setSubdomain(); // set to true --> will hide the tenant selection on client login
      }
    }
    //commonStore.setSubdomain();
  }, []);

  // app theme
  const appTheme = nanoTheme({
    direction: "ltr",
    colorMode: commonStore.darkMode ? "dark" : "light", // check user preferences
    isResponsiveFontSizes: true,
  });

  if (!commonStore.appLoaded) return <LoadingScreen />;

  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={appTheme}>
        <ThemeProvider theme={appTheme}>
          <ConfirmProvider>
            <ScrollToTop />
            <CssBaseline />
            <ToastContainer position="bottom-left" hideProgressBar draggable autoClose={3000} />
            <Outlet />
          </ConfirmProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  );
}

export default observer(App);
