import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { RelationshipType } from "../models/relationshipType";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";

export default class RelationshipTypeStore {
  relationshipTypes: RelationshipType[] = [];
  relationshipTypeMetaData: Omit<PaginatedResult<RelationshipType>, "data"> | null = null;

  loading = false; // modal window buttons loading state
  loadingInitial = false; // list view table loading state

  constructor() {
    makeAutoObservable(this);
  }

  // loading state setter
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading state setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setRelationshipTypeMetaData = (metaData: Omit<PaginatedResult<RelationshipType>, "data">) => {
    runInAction(() => {
      this.relationshipTypeMetaData = metaData;
    });
  };

  loadRelationshipTypes = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
      };
      const { data, ...metaData } = await agent.RelationshipTypes.search(params);
      runInAction(() => {
        this.relationshipTypes = data;
      });
      this.setRelationshipTypeMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  loadAllRelationshipTypes = async () => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.RelationshipTypes.list(); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.relationshipTypes = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
  createRelationshipType = async (relationshipType: RelationshipType) => {
    this.setLoading(true);
    try {
      const relationshipTypeRequestBody = {
        Display: relationshipType.display,
      };
      const response = await agent.RelationshipTypes.create(relationshipTypeRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  updateRelationshipType = async (relationshipType: RelationshipType) => {
    this.setLoading(true);
    try {
      const response = await agent.RelationshipTypes.update(relationshipType);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  get relationshipTypeSorted() {
    return Array.from(this.relationshipTypes.values()).sort((a, b) => (a.display > b.display ? 1 : b.display > a.display ? -1 : 0));
  }
  deleteRelationshipType = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.RelationshipTypes.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
}
