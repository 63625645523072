import { Roles } from "app/models/roles";
import Icons from "../../icons/sidebar";
import BusinessIcon from "@mui/icons-material/Business";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
// list of navigation items that appear in sidebar
const navList = [
  // {
  //   title: "UI Component Samples",
  //   Icon: Icons.PagesIcon,
  //   path: "/samples",
  //   roles: [Roles.root],
  //   topDivider: false, // for visually seperating sections
  // },
  {
    title: "Facility",
    Icon: MapsHomeWorkIcon,
    path: "/facilities",
    alternatePath: "/facility/:id",
    roles: [Roles.root, Roles.admin, Roles.editor],
    topDivider: true,
  },
  {
    title: "Location",
    Icon: BusinessIcon,
    path: "/locations",
    alternatePath: "/location/:id",
    roles: [Roles.root, Roles.admin, Roles.editor],

    topDivider: true,
  },
  {
    title: "Item",
    Icon: Icons.ItemIcon,
    path: "/items",
    alternatePath: "/item/:id",
    roles: [Roles.root, Roles.admin, Roles.editor],
    topDivider: true,
  },
  {
    title: "Part",
    Icon: Icons.PartIcon,
    path: "/parts",
    alternatePath: "/part/:id",
    roles: [Roles.root, Roles.admin, Roles.editor],
    topDivider: true,
  },
  {
    title: "Part Family",
    Icon: Icons.CategoryIcon,
    path: "/partFamilies",
    roles: [Roles.root, Roles.admin, Roles.editor],
    topDivider: true,
  },

  // {
  //   title: "Item Parts",
  //   Icon: Icons.InventoryIcon,
  //   path: "/itemParts",
  //   roles: [Roles.root, Roles.admin, Roles.editor],
  //   topDivider: true,
  // },
  // {
  //   title: "Relationship Type",
  //   Icon: Icons.HubIcon,
  //   path: "/relationshipTypes",
  //   roles: [Roles.root, Roles.admin, Roles.editor],
  //   topDivider: true,
  // },
  {
    title: "Users",
    Icon: Icons.PeopleOutlineIcon,
    path: "/users",
    roles: [Roles.root, Roles.admin], // for restricting to certian roles
    topDivider: true,
  },
  {
    title: "Tenants",
    Icon: Icons.AccountSettingsIcon,
    path: "/tenants",
    roles: [Roles.root],
    topDivider: false,
  },
];

export default navList;
