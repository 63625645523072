import { Roles } from "app/models/roles";
import React, { Suspense, Fragment, lazy, LazyExoticComponent, FC } from "react";
import { Route } from "react-router-dom";
import AuthGuard from "./navigation/authGuards/AuthGuard";
import GuestGuard from "./navigation/authGuards/GuestGuard";
import AppLayout from "./navigation/AppLayout";
import LoadingScreen from "./components/LoadingScreen";
import { Helmet } from "react-helmet";
import ViewerAppLayout from "navigation/ViewerAppLayout";

interface RouteType {
  guard?: (props: any) => JSX.Element;
  layout?: (props: any) => JSX.Element;
  component?: (props: any) => JSX.Element;
  routes?: RouteType[];
  path?: string;
  roles?: Roles[];
  title?: string;
}

const Loadable = (Component: LazyExoticComponent<FC>) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const NotFoundPage = Loadable(lazy(() => import("./pages/404")));
const UnauthorizedPage = Loadable(lazy(() => import("./pages/403")));
const LoginPage = Loadable(lazy(() => import("./pages/authentication/Login")));
const ForgotPasswordPage = Loadable(lazy(() => import("./pages/authentication/ForgotPassword")));
const ResetPasswordPage = Loadable(lazy(() => import("./pages/authentication/ResetPassword")));
const TenantListPage = Loadable(lazy(() => import("./pages/tenants/TenantList")));
const UserListPage = Loadable(lazy(() => import("./pages/users/UserList")));
const PartFamilyListPage = Loadable(lazy(() => import("./pages/partFamilies/PartFamilyList")));
const PartListPage = Loadable(lazy(() => import("./pages/parts/PartList")));
const PartDetailPage = Loadable(lazy(() => import("./pages/parts/PartDetail")));
const ItemListPage = Loadable(lazy(() => import("./pages/items/ItemList")));
const ItemDetailPage = Loadable(lazy(() => import("./pages/items/ItemDetail")));
const LocationDetailPage = Loadable(lazy(() => import("./pages/locations/LocationDetail")));
const StoreViewerPage = Loadable(lazy(() => import("./pages/viewer/StoreViewer")));

const CardViewerPage = Loadable(lazy(() => import("./pages/viewer/CardViewer")));
const PartViewerPage = Loadable(lazy(() => import("./pages/viewer/PartViewer")));
//const ItemPartListPage = Loadable(lazy(() => import("./pages/itemParts/ItemPartList")));
const RelationshipTypeItemListPage = Loadable(lazy(() => import("./pages/relationshipTypes/RelationshipTypeItemList")));
const SampleListPage = Loadable(lazy(() => import("./pages/samples/SampleList")));
const AccountSettingsPage = Loadable(lazy(() => import("./pages/profile/AccountSettings")));
const LocationListPage = Loadable(lazy(() => import("./pages/locations/LocationList")));
const FacilityListPage = Loadable(lazy(() => import("./pages/facilities/FacilityList")));
const FacilityDetailPage = Loadable(lazy(() => import("./pages/facilities/FacilityDetail")));

export const renderRoutes = (routes: RouteType[] = []) =>
  routes.map((route, i) => {
    const Guard = route.guard || React.Component;
    const Layout = route.layout || Fragment;
    const Component = route.component || React.Component;

    return (
      <Route
        key={i}
        path={route.path}
        element={
          <Fragment>
            {route.title && (
              <Helmet>
                <title>{route.title}</title>
              </Helmet>
            )}
            {route.guard ? (
              <Guard roles={route.roles}>
                <Layout>
                  <Component />
                </Layout>
              </Guard>
            ) : (
              <Layout>
                <Component />
              </Layout>
            )}
          </Fragment>
        }
      />
    );
  });

const appName = "DH Pace Navigator";

const routes: RouteType[] = [
  {
    path: "/",
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`,
  },
  {
    path: "/404",
    component: NotFoundPage,
    title: `${appName} | Not Found`,
  },
  {
    path: "/403",
    component: UnauthorizedPage,
    title: `${appName} | Unauthorized`,
  },
  {
    path: "/login",
    guard: GuestGuard,
    component: LoginPage,
    title: `${appName} | Login`,
  },
  {
    path: "/forgot-password",
    guard: GuestGuard,
    component: ForgotPasswordPage,
    title: `${appName} | Forgot Password`,
  },
  {
    path: "/reset-password",
    guard: GuestGuard,
    component: ResetPasswordPage,
    title: `${appName} | Reset Password`,
  },
  {
    path: "/partFamilies",
    guard: AuthGuard,
    layout: AppLayout,
    component: PartFamilyListPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Part Families`,
  },
  {
    path: "/parts",
    guard: AuthGuard,
    layout: AppLayout,
    component: PartListPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Parts`,
  },
  {
    path: "/part/:id?",
    guard: AuthGuard,
    layout: AppLayout,
    component: PartDetailPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Parts`,
  },
  {
    path: "/items",
    guard: AuthGuard,
    layout: AppLayout,
    component: ItemListPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Items`,
  },
  {
    path: "/item/:id?",
    guard: AuthGuard,
    layout: AppLayout,
    component: ItemDetailPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Items`,
  },
  {
    path: "/facilities",
    guard: AuthGuard,
    layout: AppLayout,
    component: FacilityListPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Facilities`,
  },
  {
    path: "/facility/:id",
    guard: AuthGuard,
    layout: AppLayout,
    component: FacilityDetailPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Facilities`,
  },
  {
    path: "/locations",
    guard: AuthGuard,
    layout: AppLayout,
    component: LocationListPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Locations`,
  },
  {
    path: "/location/:id?",
    guard: AuthGuard,
    layout: AppLayout,
    component: LocationDetailPage,
    roles: [Roles.root, Roles.admin, Roles.editor],
    title: `${appName} | Locations`,
  },
  // {
  //   path: "/itemParts",
  //   guard: AuthGuard,
  //   layout: AppLayout,
  //   component: ItemPartListPage,
  //   roles: [Roles.root, Roles.admin, Roles.editor],
  //   title: `${appName} | Item Parts`,
  // },
  // {
  //   path: "/relationshipTypes",
  //   guard: AuthGuard,
  //   layout: AppLayout,
  //   component: RelationshipTypeItemListPage,
  //   roles: [Roles.root, Roles.admin, Roles.editor],
  //   title: `${appName} | Relationship Types`,
  // },
  {
    path: "/samples",
    guard: AuthGuard,
    component: SampleListPage,
    roles: [Roles.root, Roles.admin],
    title: `${appName} | UI Component Samples`,
    layout: AppLayout,
  },
  {
    path: "/profile",
    guard: AuthGuard,
    component: AccountSettingsPage,
    title: `${appName} | Profile`,
    layout: AppLayout,
  },
  {
    path: "/users",
    component: UserListPage,
    guard: AuthGuard,
    roles: [Roles.root, Roles.admin],
    title: `${appName} | User Administration`,
    layout: AppLayout,
  },
  {
    path: "/tenants",
    component: TenantListPage,
    guard: AuthGuard,
    roles: [Roles.root],
    title: `${appName} | Tenant Administration`,
    layout: AppLayout,
  },
  {
    path: "/viewer",
    component: StoreViewerPage,
    guard: AuthGuard,
    roles: [Roles.viewer],
    title: `${appName} | Store Viewer`,
    layout: AppLayout,
  },

  {
    path: "/card-viewer",
    component: CardViewerPage,
    guard: AuthGuard,
    roles: [Roles.viewer],
    title: `${appName} | Store Viewer`,
    layout: ViewerAppLayout,
  },
  {
    path: "/part-viewer/:id",
    guard: AuthGuard,
    layout: ViewerAppLayout,
    component: PartViewerPage,
    roles: [Roles.viewer],
    title: `${appName} | Part Viewer`,
  },
  {
    path: "*",
    component: NotFoundPage,
  },
];

export default routes;
