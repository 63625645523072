import { makeAutoObservable, reaction, runInAction } from "mobx";
import agent from "../api/agent";
import { SearchParams } from "app/models/searchParams";
import { ItemPart } from "app/models/itemPart";
import { FacilitySearchResult } from "app/models/facility";
export default class CommonStore {
  token: string | null = window.localStorage.getItem("jwt");
  tenant: string | null = "";
  hasSubdomain: boolean = false; // set upon app loading
  title: string | null = "";
  darkMode: boolean = false;
  pageSizeDefault: number = 10;
  appLoaded: boolean = false;
  activePageTitle: string | null = "";
  activeEditName: string | null = "";
  navBarBackUrl: string | null = "";
  searchText: string | null = "";
  searchResults: FacilitySearchResult | null = null;
  isSearchOpen: boolean = false;
  constructor() {
    makeAutoObservable(this); // let MobX auto create interfaces

    reaction(
      // reaction only runs when there is a change to token. not on initialization
      () => this.token,
      (token) => {
        if (token) {
          window.localStorage.setItem("jwt", token);
        } else {
          window.localStorage.removeItem("jwt");
        }
      }
    );
  }

  setToken = (token: string | null) => {
    runInAction(() => {
      this.token = token;
    });
  };

  setSearchText = (value: string | null) => {
    runInAction(() => {
      this.searchText = value;
    });
  };

  setTenant = (tenant: string | null) => {
    runInAction(() => {
      this.tenant = tenant;
    });
  };

  setTitle = (title: string | null) => {
    runInAction(() => {
      this.title = title;
    });
  };

  setAppLoaded = () => {
    runInAction(() => {
      this.appLoaded = true;
    });
  };

  setPageSize = (size: number) => {
    runInAction(() => {
      this.pageSizeDefault = size;
    });
  };

  setActivePageTitle = (pageTitle: string) => {
    runInAction(() => {
      this.activePageTitle = pageTitle;
    });
  };

  setActiveEditName = (editName: string) => {
    runInAction(() => {
      this.activeEditName = editName;
    });
  };

  setNavBarBackUrl = (navBarUrl: string) => {
    runInAction(() => {
      this.navBarBackUrl = navBarUrl;
    });
  };

  setDarkTheme = (darkModeEnabled: boolean) => {
    runInAction(() => {
      this.darkMode = darkModeEnabled;
    });
  };

  setSubdomain = () => {
    runInAction(() => {
      this.hasSubdomain = true;
    });
  };

  searchItemParts = async () => {
    try {
      const { data, ...metaData } = await agent.StoreNavigator.search(this.searchText);
      runInAction(() => {
        this.searchResults = data;
        console.log("searchitemparts", this.searchResults);
      });
    } catch (error) {
      console.log(error);
    }
  };

  setSearchResultsOpen = (isOpen: boolean) => {
    runInAction(() => {
      this.isSearchOpen = isOpen;
    });
  };
}
