import { PartMappingViewer } from "./facility";

export interface CurrentNavigator {
  facilityId: string;
  facilityName: string;
  facilityImageUrl?: string;

  locations: NavigatorLocation[];
  activePage?: CurrentActivePage;
  activePart?: NavigatorPartViewer;
}
export interface CurrentActivePage {
  pageType: NavigatorPageType;
  entityId: string;
  pageModel: any;
}
export interface CurrentNavigatorFinal {
  id?: string | null;
  name?: string | null;
  description?: string;
  imageUrl?: string;
  logoUrl?: string;
  mappingProps?: string;
  locations?: CurrentStoreNavigatorLocationMapping[] | null;
  annotations?: CurrentStoreNavigatorLocationMapping[] | null;
  activeLocation?: CurrentStoreNavigatorLocationMapping;
  activeItem?: NavigatorLocationViewerItem;
  activePart?: NavigatorItemPartViewer;
  activePageType?: NavigatorPageType;
}
export enum NavigatorPageType {
  Facility,
  Location,
  Item,
  Part,
  PartSearch,
}
export interface NavigatorLocation {
  locationId: string;
  locationName: string;
  locationDescription?: string;
  locationImageUrl: string;
  points?: number[][];
  items: NavigatorLocationItem[];
}

export interface NavigatorLocationItem {
  itemId: string;
  itemName: string;
  itemDescription?: string;
  itemImageUrl?: string;
  parts: NavigatorItemPart[];
}

export interface NavigatorItemPart {
  partId: string;
  partName: string;
  partDescription: string;
  partNumber?: string;
  partImageUrl?: string;
  partFamilyId: string;
  partFamilyName: string;
}

export interface CurrentStoreNavigator {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
  logoUrl?: string;
  mappingProps?: string;
  locations: CurrentStoreNavigatorLocationMapping[];
  annotations: CurrentStoreNavigatorLocationMapping[];
  activePage?: CurrentActivePage;
}
export interface CurrentStoreNavigatorLocationMapping {
  id?: string;
  mapping?: string;
  mappingStyle?: string;
  locationViewer?: NavigatorLocationViewer;
}
export interface CurrentStoreNavigatorAnnotationMapping {
  id?: string;
  mapping?: string;
}
export interface NavigatorLocationViewer {
  id: string;
  name: string;
  description?: string;
  imageUrl?: string;
  logoUrl?: string;
  mappingProps?: string;
  isSelected?: boolean | false;
  items?: NavigatorLocationViewerItem[];
}

export interface NavigatorLocationViewerItem {
  id: string;
  mapping?: string;
  mappingStyle?: string;
  itemViewer?: NavigatorItemViewer;
}

export interface NavigatorItemViewer {
  id: string;
  name: string;
  codeName?: string;
  description?: string;
  displayName?: string;
  summaryDescription?: string;
  imageUrl?: string;
  logoUrl?: string;
  itemNumber?: string;
  isSelected?: boolean | false;
  partFamilyViewer?: PartFamilyViewer;
  assets?: NavAssetViewer[];
  parts?: NavigatorItemPartViewer[];
  annotations?: CurrentStoreNavigatorAnnotationMapping[];
}

export interface NavAssetViewer {
  id: string;
  name?: string;
  isPrimary?: boolean | false;
  assetType?: number;
  url?: string;
}
export interface PartFamilyViewer {
  id: string;
  name?: string;
}

export interface NavigatorItemPartViewer {
  id?: string;
  mapping?: string;
  mappingType: number | 1;
  mappingLabel?: string | "";
  listOrder?: number | 99;
  mappingStyle?: string | "";
  color?: string | "";
  groupId?: string | "";
  partViewer?: NavigatorPartViewer;
}

export interface NavigatorPartViewer {
  id: string;
  name: string;
  description?: string;
  displayName?: string;
  parentPartId?: string;
  partNumber?: string;
  manufacturerPartId?: string;
  isSelected?: boolean | false;
  isKit?: boolean | false;
  partFamilyViewer?: PartFamilyViewer;
  assets?: NavAssetViewer[];
  partKits?: NavigatorPartViewer[];
  partMappings: PartMappingViewer[];
}
