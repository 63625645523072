import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import {
  AddFacilityLocation,
  AddFacilityRequest,
  CreateFacilityAnnotation,
  Facility,
  FacilityLocation,
  UpdateFacilityAnnotation,
  UpdateFacilityLocation,
  UpdateFacilityPropMappings,
} from "../models/facility";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";
import { ShapeProp } from "components/ImageAnnotator/types";

export default class FacilityStore {
  loading = false;
  loadingInitial = false;

  selectedFacilityId: string = "";
  selectedFacility?: Facility | null;

  facilities: Facility[] = [];
  activeEditShapeProp?: ShapeProp | null;

  facilityMetaData: Omit<PaginatedResult<Facility>, "data"> | null = null;

  facilityLocations: FacilityLocation[] | null = null;
  hoverSelectedId: string = "";
  highlightId: string = "";
  highlightGroupId: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setFacilityId(facilityId: string) {
    if (this.selectedFacilityId !== facilityId) {
      this.resetFacility();
      this.selectedFacilityId = facilityId;
    }
  }
  refreshSelectedFacility(facility: Facility) {
    runInAction(() => {
      this.selectedFacility = facility;
    });
  }
  loadInitialData = async () => {
    if (!this.selectedFacilityId) throw new Error("Facility Not Selected");
    this.setLoadingInitial(true);
    try {
      const response = await agent.Facilities.details(this.selectedFacilityId);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.selectedFacility = response.data;
      });

      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  resetFacility = () => {
    runInAction(() => {
      this.selectedFacilityId = "";
      this.selectedFacility = null;
      this.facilityLocations = null;
    });
  };

  resetFacilityLocations = () => {
    runInAction(() => {
      this.facilityLocations = null;
    });
  };

  refreshFacilityLocations = (refreshedFacilityLocations: FacilityLocation[]) => {
    runInAction(() => {
      this.facilityLocations = refreshedFacilityLocations;
    });
  };

  resetSelectedFacility = (refreshedFacility: Facility) => {
    runInAction(() => {
      this.selectedFacility = refreshedFacility;
    });
  };

  get facilityConsolidatedAnnotations() {
    return this.facilityLocations
      ? Array.from(this.facilityLocations.values()).sort((a, b) =>
          a?.location?.name > b?.location?.name ? 1 : b?.location?.name > a?.location?.name ? -1 : 0
        )
      : [];
  }
  setFacilityMetaData = (metaData: Omit<PaginatedResult<Facility>, "data">) => {
    runInAction(() => {
      this.facilityMetaData = metaData;
    });
  };

  loadFacilities = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
      };
      const { data, ...metaData } = await agent.Facilities.search(params);
      runInAction(() => {
        this.facilities = data;
      });
      this.setFacilityMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  removeFacility = async () => {
    this.setLoadingInitial(true);
    try {
      runInAction(() => {
        this.selectedFacility = undefined;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
  createFacility = async (facility: AddFacilityRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.create(facility);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  updateFacility = async (facility: AddFacilityRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.update(facility);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  deleteFacility = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Facilities.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  updateFacilityPropMappings = async (facility: UpdateFacilityPropMappings) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.updateMappings(facility);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  updateFacilityAnnotation = async (facilityAnnotation: UpdateFacilityAnnotation) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.updateAnnotation(facilityAnnotation);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  createFacilityAnnotation = async (facilityAnnotation: CreateFacilityAnnotation) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.createAnnotation(facilityAnnotation);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  deleteFacilityAnnotation = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Facilities.deleteAnnotation(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      return true;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  createFacilityLocation = async (facility: AddFacilityLocation) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.createLocation(facility);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  updateFacilityLocation = async (facilityLocation: UpdateFacilityLocation) => {
    this.setLoading(true);
    try {
      const response = await agent.Facilities.updateLocation(facilityLocation);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  deleteFacilityLocation = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Facilities.deleteFacilityLocation(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      return true;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
  loadFacilityLocations = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Facilities.getLocations(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.facilityLocations = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  getSelectedFacilityAnnotations = () => {
    const shapes: ShapeProp[] = [];
    if (this?.selectedFacility && this.selectedFacility?.annotations) {
      this.selectedFacility.annotations.forEach((bm) => {
        shapes.push({ ...JSON.parse(bm.mapping), id: bm.id, mappingId: bm.id, annotationStorageType: "facility" });
      });
    }
    if (this?.selectedFacility && this?.selectedFacility?.facilityLocations) {
      this.selectedFacility.facilityLocations.forEach((fl) => {
        if (fl?.mappingStyle) {
          shapes.push({ ...JSON.parse(fl.mappingStyle), id: fl.id, mappingId: fl.id, annotationStorageType: "location" });
        }
      });
    }
    return shapes;
  };

  facilityLocationsToMappings = () => {
    const buildingMaps =
      this.facilityLocations?.map((item) => {
        const maps = item.mapping ? (JSON.parse(item.mapping) as Array<Array<number>>) : new Array<Array<number>>();
        return {
          id: item.id,
          facilityId: item.facilityId,
          locationId: item.locationId,
          points: maps,
          mappingType: item.mappingType,
          mappingStyle: item.mappingStyle,
          mappingLabel: item.mappingLabel,
        };
      }) || [];
    return buildingMaps;
  };

  get facilityLocationsSorted() {
    return this.facilityLocations
      ? Array.from(this.facilityLocations.values()).sort((a, b) =>
          a?.location?.name > b?.location?.name ? 1 : b?.location?.name > a?.location?.name ? -1 : 0
        )
      : [];
  }

  setActiveEditShape = (state: ShapeProp | null) => {
    runInAction(() => {
      this.activeEditShapeProp = state;
    });
  };

  setHoverSelectedId(id?: string) {
    runInAction(() => {
      this.hoverSelectedId = id ?? "";
    });
  }
  setHighlightId(id?: string) {
    runInAction(() => {
      this.highlightId = id ?? "";
    });
  }
  setHighlightGroupId(id?: string) {
    runInAction(() => {
      this.highlightGroupId = id ?? "";
    });
  }
}
