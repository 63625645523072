import { Brightness4 } from "@mui/icons-material";
import { alpha, AppBar, Box, ClickAwayListener, IconButton, InputBase, List, ListItem, Paper, Popper, styled, Toolbar } from "@mui/material";
import { H2 } from "../../components/Typography";
import { FC, useCallback, useEffect, useRef } from "react";
import ProfileButton from "./profileButton/ProfileButton";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import ThemeIcon from "../../icons/ThemeIcon";
import TopNavRoot from "./TopNavRoot";
import SearchIcon from "icons/SearchIcon";
import SearchDropdownResults from "./SearchDropdownResults";

export interface SearchResultProps {
  isMobile?: boolean;
  anchorEl: HTMLElement | null;
}
const StyledPopper = styled(Popper)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: { maxWidth: "790px" },
}));
const SearchDropdown: FC<SearchResultProps> = ({ isMobile, anchorEl }: SearchResultProps) => {
  const { commonStore } = useStore();
  let mode = "dark";
  if (commonStore.darkMode === true) {
    mode = "dark";
  } else {
    mode = "light";
  }

  const onClickAway = () => {
    if (commonStore.isSearchOpen) {
      commonStore.setSearchResultsOpen(false);
    }
  };

  useEffect(() => {
    console.log("searchText", commonStore?.searchResults);
    if (commonStore?.searchText) commonStore.searchItemParts();
  }, [commonStore?.searchText]);

  useEffect(() => {
    console.log("searchResults", commonStore?.searchResults);
    if (commonStore?.searchText) commonStore.setSearchResultsOpen(true);
  }, [commonStore?.searchResults]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <StyledPopper open={commonStore.isSearchOpen} anchorEl={anchorEl} placement="bottom" disablePortal={true}>
        <SearchDropdownResults isMobile={isMobile}></SearchDropdownResults>
      </StyledPopper>
    </ClickAwayListener>
  );
};

export default observer(SearchDropdown);
