import { makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { PartFamily } from "../models/partFamily";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";

export default class PartFamilyStore {
  partFamilies: PartFamily[] = [];
  partFamilyMetaData: Omit<PaginatedResult<PartFamily>, "data"> | null = null;

  loading = false; // modal window buttons loading state
  loadingInitial = false; // list view table loading state

  constructor() {
    makeAutoObservable(this);
  }

  // loading state setter
  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };
  // loading state setter
  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  // set pagination meta data
  setPartFamilyMetaData = (metaData: Omit<PaginatedResult<PartFamily>, "data">) => {
    runInAction(() => {
      this.partFamilyMetaData = metaData;
    });
  };

  // load part families - paginated list of part families from api
  loadPartFamilies = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
      };
      const { data, ...metaData } = await agent.PartFamilies.search(params);
      runInAction(() => {
        this.partFamilies = data;
      });
      this.setPartFamilyMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };
  loadAllPartFamilies = async () => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.PartFamilies.list(); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.partFamilies = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
  // create part family
  createPartFamily = async (partFamily: PartFamily) => {
    this.setLoading(true);
    try {
      const partFamilyRequestBody = {
        Name: partFamily.name,
        Description: partFamily.description,
      };
      const response = await agent.PartFamilies.create(partFamilyRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  get partFamiliesSorted() {
    return Array.from(this.partFamilies.values()).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }
  // update part family
  updatePartFamily = async (partFamily: PartFamily) => {
    this.setLoading(true);
    try {
      const response = await agent.PartFamilies.update(partFamily);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  // delete part family
  deletePartFamily = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.PartFamilies.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
}
