import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { FC, PropsWithChildren } from "react";
import { topBarHeight } from "./consts";
import TopNavWrapper from "./TopNavWrapper";
import { H2, H4 } from "components/Typography";
import TopNavLink from "./TopNavLink";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { primary, secondary } from "theme/themeColors";
interface TopNavProps {
  title: string | null;
  editTitle: string | null;
  href?: string | null;
  withoutBorder?: boolean;
  isAlignToRight?: boolean;
}
const TopNavRoot: FC<PropsWithChildren<TopNavProps>> = ({ title, editTitle, href, withoutBorder = false, isAlignToRight = true, children }) => {
  return (
    <TopNavWrapper withoutBorder={withoutBorder}>
      {href && <TopNavLink to={href} />}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {title != null && title != "" && (
          <>
            <H2 fontSize={21} fontWeight="600" color="secondary.400">
              {title}
            </H2>
            <ChevronRightIcon style={{ fontSize: 28, color: "#94A4C4" }}></ChevronRightIcon>
          </>
        )}
        {editTitle != null && editTitle != "" && (
          <H2 fontSize={21} fontWeight="700" color="text.primary">
            {editTitle}
          </H2>
        )}
      </Box>
    </TopNavWrapper>
  );
};
export default observer(TopNavRoot);
