import { SvgIcon, SvgIconProps } from "@mui/material";
import React from "react";

const Part = (props: SvgIconProps) => {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path
        d="M 66.108 10 L 33.893 10 C 33.249 10.014 32.732 10.537 32.725 11.181 L 32.725 15.087 C 32.732 15.731 33.249 16.254 33.893 16.268 L 66.108 16.268 C 66.749 16.247 67.262 15.729 67.276 15.087 L 67.276 11.181 C 67.262 10.54 66.749 10.021 66.108 10 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 40.994 19.396 L 33.893 19.396 C 33.249 19.41 32.732 19.933 32.725 20.577 L 32.725 22.913 C 32.732 23.557 33.249 24.08 33.893 24.094 L 40.94 24.094 C 41.586 24.08 42.107 23.559 42.121 22.913 L 42.121 20.577 C 42.109 19.951 41.619 19.438 40.994 19.396 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 53.517 19.396 L 46.484 19.396 C 45.837 19.41 45.317 19.931 45.302 20.577 L 45.302 22.913 C 45.317 23.559 45.837 24.08 46.484 24.094 L 53.517 24.094 C 54.164 24.08 54.684 23.559 54.698 22.913 L 54.698 20.577 C 54.684 19.931 54.164 19.41 53.517 19.396 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 66.108 19.396 L 59.007 19.396 C 58.361 19.41 57.84 19.931 57.826 20.577 L 57.826 22.913 C 57.84 23.559 58.361 24.08 59.007 24.094 L 66.108 24.094 C 66.749 24.073 67.262 23.554 67.276 22.913 L 67.276 20.577 C 67.262 19.936 66.749 19.417 66.108 19.396 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 40.994 27.221 L 33.893 27.221 C 33.249 27.236 32.732 27.758 32.725 28.403 L 32.725 30.752 C 32.739 31.391 33.254 31.905 33.893 31.919 L 40.94 31.919 C 41.581 31.906 42.1 31.393 42.121 30.752 L 42.121 28.403 C 42.109 27.776 41.619 27.263 40.994 27.221 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 53.517 27.221 L 46.484 27.221 C 45.837 27.236 45.317 27.756 45.302 28.403 L 45.302 30.752 C 45.324 31.393 45.842 31.906 46.484 31.919 L 53.517 31.919 C 54.159 31.906 54.677 31.393 54.698 30.752 L 54.698 28.403 C 54.684 27.756 54.164 27.236 53.517 27.221 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 66.108 27.221 L 59.007 27.221 C 58.361 27.236 57.84 27.756 57.826 28.403 L 57.826 30.752 C 57.847 31.393 58.366 31.906 59.007 31.919 L 66.108 31.919 C 66.744 31.899 67.255 31.388 67.276 30.752 L 67.276 28.403 C 67.262 27.761 66.749 27.243 66.108 27.221 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 40.994 35.06 L 33.893 35.06 C 33.254 35.075 32.739 35.589 32.725 36.228 L 32.725 38.577 C 32.732 39.221 33.249 39.744 33.893 39.758 L 40.94 39.758 C 41.586 39.744 42.107 39.224 42.121 38.577 L 42.121 36.228 C 42.102 35.607 41.614 35.102 40.994 35.06 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 53.517 35.06 L 46.484 35.06 C 45.842 35.074 45.324 35.587 45.302 36.228 L 45.302 38.577 C 45.317 39.224 45.837 39.744 46.484 39.758 L 53.517 39.758 C 54.164 39.744 54.684 39.224 54.698 38.577 L 54.698 36.228 C 54.677 35.587 54.159 35.074 53.517 35.06 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 66.108 35.06 L 59.007 35.06 C 58.366 35.074 57.847 35.587 57.826 36.228 L 57.826 38.577 C 57.84 39.224 58.361 39.744 59.007 39.758 L 66.108 39.758 C 66.749 39.737 67.262 39.219 67.276 38.577 L 67.276 36.228 C 67.255 35.592 66.744 35.081 66.108 35.06 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 40.994 42.886 L 33.893 42.886 C 33.249 42.9 32.732 43.423 32.725 44.067 L 32.725 46.416 C 32.739 47.055 33.254 47.57 33.893 47.584 L 40.94 47.584 C 41.581 47.57 42.1 47.057 42.121 46.416 L 42.121 44.067 C 42.109 43.441 41.619 42.928 40.994 42.886 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 53.517 42.886 L 46.484 42.886 C 45.837 42.9 45.317 43.421 45.302 44.067 L 45.302 46.416 C 45.324 47.057 45.842 47.57 46.484 47.584 L 53.517 47.584 C 54.159 47.57 54.677 47.057 54.698 46.416 L 54.698 44.067 C 54.684 43.421 54.164 42.9 53.517 42.886 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 66.108 42.886 L 59.007 42.886 C 58.361 42.9 57.84 43.421 57.826 44.067 L 57.826 46.416 C 57.847 47.057 58.366 47.57 59.007 47.584 L 66.108 47.584 C 66.744 47.563 67.255 47.052 67.276 46.416 L 67.276 44.067 C 67.262 43.426 66.749 42.907 66.108 42.886 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <path
        d="M 83.705 60.268 L 52.014 60.268 L 52.014 53.128 C 52.014 51.578 50.336 50.609 48.994 51.384 C 48.371 51.744 47.987 52.408 47.987 53.128 L 47.987 60.268 L 16.296 60.268 C 15.184 60.268 14.282 61.17 14.282 62.282 L 14.282 72.832 C 14.282 74.382 15.96 75.351 17.302 74.576 C 17.925 74.216 18.309 73.552 18.309 72.832 L 18.309 64.295 L 47.987 64.295 L 47.987 71.221 C 47.987 72.771 49.665 73.74 51.007 72.965 C 51.63 72.605 52.014 71.941 52.014 71.221 L 52.014 64.295 L 81.692 64.295 L 81.692 72.832 C 81.692 74.382 83.37 75.351 84.712 74.576 C 85.335 74.216 85.719 73.552 85.719 72.832 L 85.719 62.349 C 85.757 61.211 84.844 60.268 83.705 60.268 Z"
        transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)"
      />
      <circle cx="16.296" cy="83.436" r="6.564" transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)" />
      <circle cx="49.759" cy="83.436" r="6.564" transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)" />
      <circle cx="83.705" cy="83.436" r="6.564" transform="matrix(1, 0, 0, 1, 0, -8.881784197001252e-16)" />
    </SvgIcon>
  );
};

export default Part;
