import agent from "app/api/agent";
import { PaginatedResult } from "app/models/responseWrappers";
import { SearchParams } from "app/models/searchParams";
import { makeAutoObservable, reaction, runInAction } from "mobx";
import { AddLocationItemRequest, Location, UpdateLocationItem } from "../models/location";
import { LocationItem } from "app/models/locationItem";
export default class LocationStore {
  locations: Location[] = [];
  locationMetaData: Omit<PaginatedResult<Location>, "data"> | null = null;
  currentEditLocation: Location | null = null;
  currentEditLocationId: string | null = "";
  loading = false; // modal window buttons loading state
  loadingInitial = false; // list view table loading state
  locationItems: LocationItem[] | null = null;
  loadingLocationItems = false;
  constructor() {
    makeAutoObservable(this);
    reaction(
      () => this.currentEditLocation,
      (currentEditLocation) => {
        // triggered when there is a change to currentUser
        this.locationItems = null;
      }
    );
  }

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  setLoadingLocationItems = (state: boolean) => {
    runInAction(() => {
      this.loadingLocationItems = state;
    });
  };

  setLocationMetaData = (metaData: Omit<PaginatedResult<Location>, "data">) => {
    runInAction(() => {
      this.locationMetaData = metaData;
    });
  };

  setCurrentEditLocationId = (state: string | null = "") => {
    runInAction(() => {
      this.currentEditLocationId = state;
    });
  };

  setCurrentEditLocation = (state: Location | null) => {
    runInAction(() => {
      this.currentEditLocation = state;
    });
  };

  resetLocationItems = () => {
    runInAction(() => {
      this.locationItems = null;
    });
  };

  getCurrentEditLocation = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Locations.details(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      this.setCurrentEditLocation(response.data);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadLocations = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "",
    sortDirection: "asc" | "desc" | null | undefined
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: SearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
      };
      const { data, ...metaData } = await agent.Locations.search(params);
      runInAction(() => {
        this.locations = data;
      });
      this.setLocationMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadAllLocations = async () => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Locations.list();
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.locations = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  get locationsSorted() {
    return Array.from(this.locations.values()).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  createLocation = async (location: Location) => {
    this.setLoading(true);
    try {
      const locationRequestBody = {
        Name: location.name,
      };
      const response = await agent.Locations.create(locationRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  updateLocation = async (location: Location) => {
    this.setLoading(true);
    try {
      const response = await agent.Locations.update(location);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  deleteLocation = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Locations.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  deleteLocationItem = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Locations.deleteItem(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  setLocationItems = (state: LocationItem[]) => {
    runInAction(() => {
      this.locationItems = state;
    });
  };

  loadLocationItems = async (id: string) => {
    try {
      const response = await agent.Locations.getItems(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.locationItems = response.data;
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  updateLocationItemMapping = async (locationItem: UpdateLocationItem) => {
    this.setLoading(true);
    try {
      const response = await agent.Locations.updateItem(locationItem);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  createLocationItem = async (locationItem: AddLocationItemRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Locations.createItem(locationItem);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
}
