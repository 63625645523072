import { createContext, useContext } from "react";
import AppUserStore from "./appUserStore";
import CommonStore from "./commonStore";
import TenantStore from "./tenantStore";
import CurrentUserStore from "./currentUserStore";
import PartFamilyStore from "./partFamilyStore";
import PartStore from "./partStore";
import ItemStore from "./itemStore";
import LocationStore from "./locationStore";
import ItemPartStore from "./itemPartStore";
import RelationshipTypeStore from "./relationshipTypeStore";
import AssetStore from "./assetStore";
import FacilityStore from "./facilityStore";
import EditorStore from "./editorStore";
import NavigatorStore from "./navigatorStore";
import ViewerStore from "./viewerStore";
// base Mobx store
interface Store {
  commonStore: CommonStore;
  currentUserStore: CurrentUserStore;
  appUserStore: AppUserStore;
  tenantStore: TenantStore;
  partFamilyStore: PartFamilyStore;
  partStore: PartStore;
  itemStore: ItemStore;
  relationshipTypeStore: RelationshipTypeStore;
  itemPartStore: ItemPartStore;
  locationStore: LocationStore;
  assetStore: AssetStore;
  facilityStore: FacilityStore;

  navigatorStore: NavigatorStore;
  viewerStore: ViewerStore;
  editorStore: EditorStore;
}

export const store: Store = {
  commonStore: new CommonStore(),
  currentUserStore: new CurrentUserStore(),
  appUserStore: new AppUserStore(),
  tenantStore: new TenantStore(),
  partFamilyStore: new PartFamilyStore(),
  partStore: new PartStore(),
  itemStore: new ItemStore(),
  relationshipTypeStore: new RelationshipTypeStore(),
  itemPartStore: new ItemPartStore(),
  locationStore: new LocationStore(),
  assetStore: new AssetStore(),
  facilityStore: new FacilityStore(),
  navigatorStore: new NavigatorStore(),
  viewerStore: new ViewerStore(),
  editorStore: new EditorStore(),
};

export const StoreContext = createContext(store);

export function useStore() {
  return useContext(StoreContext);
}
