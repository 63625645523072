import { Brightness4 } from "@mui/icons-material";
import { AppBar, Box, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled, Toolbar } from "@mui/material";
import { H2 } from "../../components/Typography";
import { FC, useState } from "react";
import ProfileButton from "./profileButton/ProfileButton";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import ThemeIcon from "../../icons/ThemeIcon";
import TopNavRoot from "./TopNavRoot";
import SearchBar from "./SearchBar";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
// custom styled components
const StyledNavbar = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
  display: "flex",
}));
const StyledListItemButton = styled(ListItemButton)(({ theme }) => ({
  justifyContent: "flex-start",
  flexGrow: 0,
  paddingTop: "0rem",
  paddingBottom: "0rem",
  "&:hover": { backgroundColor: "transparent" },
  [theme.breakpoints.down("md")]: { flexBasis: "150px" },
  [theme.breakpoints.up("lg")]: { flexBasis: "300px" },
}));
const StyledProfileButton = styled(Box)(({ theme }) => ({
  justifyContent: "flex-end",
  display: "flex",
  [theme.breakpoints.up("md")]: { flexBasis: "300px" },
}));
const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledSearchBox = styled(Box)(({ theme }) => ({
  "@media (min-width: 0px)": {
    flexGrow: 1,
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
}));

const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": { backgroundColor: "transparent" },
}));

// main component
const ViewerNavbar: FC = () => {
  const { commonStore } = useStore();
  const drawerWidth = 240;
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  let mode = "dark";
  if (commonStore.darkMode === true) {
    mode = "dark";
  } else {
    mode = "light";
  }

  return (
    <>
      <StyledNavbar position="sticky">
        <StyledToolBar sx={{ flexWrap: { xs: "wrap", md: "nowrap" } }}>
          <StyledListItemButton disableRipple>
            <img src="/logo/DH-Pace-logo.png" alt="DH Pace Logo" height={50} />
          </StyledListItemButton>

          <StyledSearchBox sx={{ order: { xs: 3, md: 2 } }}>
            <SearchBar></SearchBar>
          </StyledSearchBox>
          <StyledProfileButton sx={{ order: { xs: 2, md: 3 }, flexGrow: { xs: 1, md: 0 } }}>
            <ProfileButton></ProfileButton>
          </StyledProfileButton>
        </StyledToolBar>
      </StyledNavbar>
    </>
  );
};

export default observer(ViewerNavbar);
