import { Brightness4 } from "@mui/icons-material";
import { alpha, AppBar, Box, debounce, IconButton, InputBase, styled, Toolbar } from "@mui/material";
import { H2 } from "../../components/Typography";
import { FC, useEffect, useRef, useState } from "react";
import ProfileButton from "./profileButton/ProfileButton";
import { useStore } from "../../app/stores/store";
import { observer } from "mobx-react-lite";
import ThemeIcon from "../../icons/ThemeIcon";
import TopNavRoot from "./TopNavRoot";
import SearchIcon from "icons/SearchIcon";
import SearchDropdown from "./SearchDropdown";
import { useAsyncDebounce } from "react-table";

// custom styled components
const StyledNavbar = styled(AppBar)(() => ({
  zIndex: 11,
  boxShadow: "none",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  backdropFilter: "blur(6px)",
  backgroundColor: "transparent",
}));

const StyledToolBar = styled(Toolbar)(() => ({
  "@media (min-width: 0px)": {
    paddingLeft: 0,
    paddingRight: 0,
    minHeight: "auto",
  },
}));
const StyledSearchBox = styled(Box)(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: { maxWidth: "800px" },
}));
const StyledIconButton = styled(IconButton)(() => ({
  "&:hover": { backgroundColor: "transparent" },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(0),
  marginLeft: theme.spacing(0),
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    //marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  color: theme.palette.text.primary,
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    border: "2px solid",
    borderRadius: "8px",
    color: theme.palette.text.primary,
    borderColor: theme.palette.mode === "light" ? theme.palette.secondary[300] : theme.palette.divider,
    "& .MuiPopover-paper": { boxShadow: "none" },
    [theme.breakpoints.up("md")]: {
      width: "100%",
    },
  },
}));
// main component
const SearchBar: FC = () => {
  const { commonStore } = useStore();
  const formRef = useRef<HTMLFormElement>(null);
  const searchBar = useRef<HTMLFormElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [query, setQuery] = useState("");

  let mode = "dark";
  if (commonStore.darkMode === true) {
    mode = "dark";
  } else {
    mode = "light";
  }
  const onSearch = (value: string) => {
    //commonStore.setSearchText(value);
    setQuery(value);
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  useEffect(() => {
    formRef;
  }, [anchorEl]);
  useEffect(() => {
    const timeOutId = setTimeout(() => commonStore.setSearchText(query), 500);
    return () => clearTimeout(timeOutId);
  }, [query]);
  return (
    <StyledSearchBox>
      <form onSubmit={onSubmit} ref={formRef}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            ref={searchBar}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              onSearch(e.target.value);
              setAnchorEl(e.currentTarget);
            }}
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
        {anchorEl && <SearchDropdown isMobile={false} anchorEl={anchorEl}></SearchDropdown>}
      </form>
    </StyledSearchBox>
  );
};

export default observer(SearchBar);
