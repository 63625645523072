import { reaction, makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { AddPartKitRequest, Part, PartAsset, PartKit } from "../models/part";
import { PartSearchParams, SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";
import { trace } from "mobx";
export default class PartStore {
  parts: Part[] = [];
  partAssets: PartAsset[] | null = null;
  partKits: PartKit[] | null = null;
  partMetaData: Omit<PaginatedResult<Part>, "data"> | null = null;
  currentEditPart: Part | null = null;
  currentEditId: string | null = "";
  loading = false; // modal window buttons loading state
  loadingInitial = false; // list view table loading state
  isPartAssetsLoaded = false;
  isPartKitsLoaded = false;
  isErrorLoadingPartAssets = false;
  isErrorLoadingPartKits = false;
  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.currentEditId,
      (currentEditId) => {
        this.currentEditPart = null;
        this.isPartAssetsLoaded = false;
        this.isErrorLoadingPartAssets = false;
        this.partAssets = null;
        this.partKits = null;
      }
    );
  }

  setCurrentEditId = (state: string | null = "") => {
    runInAction(() => {
      this.currentEditId = state;
    });
  };

  setCurrentEdit = (state: Part | null) => {
    runInAction(() => {
      console.log("partstore setCurrentEdit", state);
      this.currentEditPart = state;
    });
  };

  setLoadingInitial = (state: boolean) => {
    runInAction(() => {
      this.loadingInitial = state;
    });
  };

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };

  setIsPartAssetsLoaded = (state: boolean) => {
    runInAction(() => {
      this.isPartAssetsLoaded = state;
    });
  };

  setIsPartKitsLoaded = (state: boolean) => {
    runInAction(() => {
      this.isPartKitsLoaded = state;
    });
  };

  resetPartKits = () => {
    runInAction(() => {
      this.partKits = null;
    });
  };
  // set pagination meta data
  setPartMetaData = (metaData: Omit<PaginatedResult<Part>, "data">) => {
    console.log("setPartMetaData", metaData);
    runInAction(() => {
      this.partMetaData = metaData;
    });
  };

  get partsSorted() {
    return Array.from(this.parts.values()).sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0));
  }

  loadParts = async (
    pageNumber: number = 1,
    pageSize: number = 5,
    keyword: string = "",
    orderBy: string = "name",
    sortDirection: "asc" | "desc" | null | undefined = "asc",
    partFamilyId: string = "",
    isKit: boolean = false
  ) => {
    this.setLoadingInitial(true);
    try {
      const params: PartSearchParams = {
        pageNumber,
        pageSize,
        keyword,
        orderBy,
        sortDirection,
        partFamilyId,
        isKit,
      };
      const { data, ...metaData } = await agent.Parts.search(params);
      runInAction(() => {
        this.parts = data;
      });
      this.setPartMetaData(metaData);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
    }
  };

  loadAllParts = async () => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.list(); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.parts = response.data;
      });
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadAssetsForPart = async (id: string) => {
    try {
      this.isErrorLoadingPartAssets = false;
      this.setIsPartAssetsLoaded(false);
      console.log("loadassets");

      const response = await agent.Parts.getAssets(id); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.partAssets = response.data;
        this.isErrorLoadingPartAssets = false;
      });
      this.setIsPartAssetsLoaded(true);
    } catch (error) {
      console.log(error);
      this.isErrorLoadingPartAssets = false;
      throw error;
    }
  };

  loadPartKitsForPart = async (id: string) => {
    try {
      this.isErrorLoadingPartKits = false;
      this.setIsPartKitsLoaded(false);

      const response = await agent.Parts.getKitParts(id); // full list from api
      if (!response.succeeded) throw new Error(response.messages[0]);
      runInAction(() => {
        this.partKits = response.data;
        this.isErrorLoadingPartKits = false;
      });
      this.setIsPartKitsLoaded(true);
    } catch (error) {
      console.log(error);
      this.isErrorLoadingPartKits = false;
      throw error;
    }
  };

  // create part
  createPart = async (part: Part) => {
    this.setLoading(true);
    try {
      const partRequestBody = {
        Name: part.name,
        Description: part.description ?? "",
        DisplayName: part.displayName,
        PartNumber: part.partNumber ?? "",
        PartFamilyId: part.partFamilyId ?? "",
        IsKit: part.isKit ?? false,
      };
      const response = await agent.Parts.create(partRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  // update part
  updatePart = async (part: Part) => {
    this.setLoading(true);
    try {
      const response = await agent.Parts.update(part);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  // delete part
  deletePart = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.delete(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  getPart = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.get(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  loadCurrentEditPart = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.get(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
      console.log("setCurrentEditPart Response", response.data);
      this.setCurrentEdit(response.data);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  createPartKit = async (partKit: AddPartKitRequest) => {
    this.setLoading(true);
    try {
      const partKitRequestBody = {
        PartId: partKit.PartId,
        ChildPartId: partKit.ChildPartId,
        ListOrder: partKit.ListOrder,
      };
      const response = await agent.Parts.createKitPart(partKitRequestBody);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  // update part
  updatePartKit = async (partKit: AddPartKitRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Parts.updateKitPart(partKit);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };

  deletePartKit = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.deleteKitPart(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };

  deletePartAsset = async (id: string) => {
    this.setLoadingInitial(true);
    try {
      const response = await agent.Parts.deletePartAsset(id);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoadingInitial(false);
    } catch (error) {
      console.log(error);
      this.setLoadingInitial(false);
      throw error;
    }
  };
}
