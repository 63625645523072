import AccountSettingsIcon from "./AccountSettings";
import PagesIcon from "./Pages";
import UserManagementIcon from "./UserManagement";
import PartIcon from "./Part";
import ItemIcon from "./Item";
import UserProfileIcon from "./UserProfile";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import CategoryIcon from "@mui/icons-material/Category";
import InventoryIcon from "@mui/icons-material/Inventory";
import HubIcon from "@mui/icons-material/Hub";
export default {
  PagesIcon,
  UserProfileIcon,
  UserManagementIcon,
  AccountSettingsIcon,
  PartIcon,
  ItemIcon,
  HubIcon,
  CategoryIcon,
  InventoryIcon,
  PeopleOutlineIcon,
};
