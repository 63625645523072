import { Box, Divider, List, ListItemButton, styled, Tooltip } from "@mui/material";
import ScrollBar from "simplebar-react";
import navList from "./navList";
import { useLocation, useNavigate } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import { Roles } from "app/models/roles";
import { Fragment } from "react";
import { matchPath } from "react-router";

const StyledListItemButton = styled(ListItemButton)(() => ({
  marginBottom: "1rem",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
}));
const MainMenu = styled(Box)(({ theme }) => ({
  width: 80,
  height: "100%",
  position: "fixed",
  left: 0,
  boxShadow: theme.shadows[2],
  zIndex: theme.zIndex.drawer + 11,
  transition: "left 0.3s ease",
  backgroundColor: theme.palette.background.paper,
}));
// root component
const SideBar = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { currentUserStore } = useStore();
  const isPathActive = (path: string, alternatePath?: string): boolean => {
    if (path === pathname) return true;
    if (alternatePath) {
      const match = matchPath(
        {
          path: alternatePath,
        },
        location.pathname
      );
      if (match) return true;
    }
    return false;
  };
  return (
    <MainMenu>
      <List sx={{ height: "100%" }}>
        <StyledListItemButton disableRipple>
          <img src="/logo/dh-pace-log-small.png" alt="DH Pace Logo" width={31} />
        </StyledListItemButton>
        <ScrollBar style={{ maxHeight: "calc(100% - 50px)" }}>
          {navList
            .filter((item) => !item.roles || item.roles.includes(currentUserStore.currentUser?.roleId as Roles))
            .map((nav, index) => (
              <Fragment key={index}>
                {nav.topDivider && <Divider variant="middle" sx={{ marginBottom: "15px" }} />}
                <Tooltip title={nav.title} placement="right">
                  <StyledListItemButton disableRipple onClick={() => navigate(nav.path)}>
                    <nav.Icon
                      sx={{
                        color: isPathActive(nav.path, nav.alternatePath) ? "primary.main" : "secondary.400",
                      }}
                    />
                  </StyledListItemButton>
                </Tooltip>
              </Fragment>
            ))}
        </ScrollBar>
      </List>
    </MainMenu>
  );
};

export default observer(SideBar);
