import { reaction, makeAutoObservable, runInAction } from "mobx";
import agent from "../api/agent";
import { Part } from "../models/part";
import { SearchParams } from "../models/searchParams";
import { PaginatedResult } from "../models/responseWrappers";
import { AddPartAssetRequest, AddItemAssetRequest } from "app/models/asset";

export default class AssetStore {
  loading = false;
  loadingInitial = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (state: boolean) => {
    runInAction(() => {
      this.loading = state;
    });
  };
  createPartAsset = async (assetRequest: AddPartAssetRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Assets.createPartAsset(assetRequest);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
  createItemAsset = async (assetRequest: AddItemAssetRequest) => {
    this.setLoading(true);
    try {
      const response = await agent.Assets.createItemAsset(assetRequest);
      if (!response.succeeded) throw new Error(response.messages[0]);
      this.setLoading(false);
      return response.data;
    } catch (error) {
      console.log(error);
      this.setLoading(false);
      throw error;
    }
  };
}
